define('floor/models/sms-subscription', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;
  exports.default = Model.extend({
    employee: belongsTo('employee'),

    phoneNumber: attr('string'),
    channels: attr('array'),

    verificationChallenge: attr('string', { writeOnly: true }),
    verified: attr('boolean', { readOnly: true })
  });
});