define('floor/controllers/grades', ['exports', 'ember-parachute'], function (exports, _emberParachute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var queryParams = new _emberParachute.default({
    sort: {
      defaultValue: 'created-at'
    },

    sellerId: {
      defaultValue: null
    }
  });

  exports.default = Ember.Controller.extend(queryParams.Mixin, {
    store: Ember.inject.service(),

    sorts: [['created-at', 'Date']]
  });
});