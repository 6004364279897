define('floor/components/accountable-grade-creator/component', ['exports', 'floor/validations/accountable-grade'], function (exports, _accountableGrade) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    GradeValidations: _accountableGrade.default,

    tagName: '',

    store: Ember.inject.service(),

    seller: null,
    cart: null,

    grade: null,

    didReceiveAttrs: function didReceiveAttrs() {
      var cart = Ember.get(this, 'cart');
      var box = Ember.get(cart, 'boxes.length') === 1 ? Ember.get(cart, 'boxes.firstObject') : null;

      Ember.set(this, 'grade', Ember.get(this, 'store').createRecord('collection', {
        cart: cart,
        box: box,

        quantity: 1
      }));
    }
  });
});