define('floor/controllers/carts/new', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    actions: {
      saveCart: function saveCart(changeset, cart) {
        var _this = this;

        return this.validateAndSave(changeset, cart).then(function () {
          Ember.get(_this, 'notify').success("Shipment saved");

          return _this.transitionToRoute('collecting', Ember.get(cart, 'id'));
        });
      }
    }
  });
});