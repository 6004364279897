define('floor/components/lot-creator/component', ['exports', 'floor/validations/lot'], function (exports, _lot) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    Validations: _lot.default,

    lot: null
  }).reopenClass({
    positionalParams: ['lot']
  });
});