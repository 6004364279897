define('floor/models/collector', ['exports', 'floor/models/clientable', 'ember-data'], function (exports, _clientable, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo,
      hasMany = _emberData.default.hasMany;
  exports.default = _clientable.default.extend({
    name: attr('string'),
    company: attr('string'),

    email: attr('string'),
    phone: attr('string'),
    address: attr('string'),

    location: belongsTo('location'),
    orders: hasMany('order'),

    closedOrdersCount: attr('number', { readOnly: true }),
    closedOrdersTotal: attr('number', { readOnly: true }),
    closedOrdersValue: attr('number', { readOnly: true }),

    closedOrdersExchangedTotal: attr('number', { readOnly: true }),
    closedOrdersExchangedValue: attr('number', { readOnly: true }),

    createdAt: attr('date', { readOnly: true }),

    prefersExchanged: attr('boolean'),

    initials: Ember.computed('name', function () {
      if (Ember.get(this, 'name')) {
        return Ember.get(this, 'name').trim().split(' ').map(function (n) {
          return (n[0] || '').toUpperCase();
        }).join('');
      }
    })
  });
});