define('floor/models/consignor', ['exports', 'floor/models/clientable', 'ember-data'], function (exports, _clientable, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var belongsTo = _emberData.default.belongsTo,
      attr = _emberData.default.attr;
  exports.default = _clientable.default.extend({
    location: belongsTo('location'),
    name: attr('string'),

    closedConsignmentsCount: attr('number', { readOnly: true }),
    closedConsignmentsTotal: attr('number', { readOnly: true }),
    closedConsignmentsValue: attr('number', { readOnly: true }),

    closedConsignmentsExchangedTotal: attr('number', { readOnly: true }),
    closedConsignmentsExchangedValue: attr('number', { readOnly: true })
  });
});