define('floor/controllers/carts/closed', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    carts: Ember.inject.controller(),
    sellerId: Ember.computed.alias('carts.sellerId')
  });
});