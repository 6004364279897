define('floor/models/worker', ['exports', 'floor/models/profile', 'ember-data'], function (exports, _profile, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo,
      hasMany = _emberData.default.hasMany;
  exports.default = _profile.default.extend({
    seller: belongsTo('seller'),
    locations: hasMany('location'),

    ackedAccuracy: attr('boolean', { writeOnly: true }),
    createdAt: attr('date', { readOnly: true }),

    role: attr('string'),

    isArchived: attr('boolean', { defaultValue: false }),
    archivedAt: attr('date', { readOnly: true }),

    addLocations: function addLocations(locations) {
      var adapter = this.store.adapterFor('worker');
      return adapter.addLocations(this, locations.mapBy('id'));
    },
    removeLocations: function removeLocations(locations) {
      var adapter = this.store.adapterFor('worker');
      return adapter.removeLocations(this, locations.mapBy('id'));
    }
  });
});