define('floor/controllers/accept-invitation', ['exports', 'ccon-common/mixins/saving', 'ccon-common/utils/extract-errors'], function (exports, _saving, _extractErrors) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_saving.default, {
    session: Ember.inject.service(),

    actions: {
      saveInvitation: function saveInvitation(changeset, invitation) {
        var _this = this;

        var email = Ember.get(invitation, 'email');
        var password = changeset.get('password');

        return this.validateAndSave(changeset, invitation).then(function () {
          Ember.get(_this, 'notify').success("Invitation accepted");

          var scope = 'employee';

          return Ember.get(_this, 'session').authenticate('authenticator:application', email, password, scope || undefined).then(function () {
            return _this.transitionToRoute('/');
          }).catch(function (reason) {
            if (reason.error) {
              (0, _extractErrors.default)([reason.error], changeset.addError.bind(changeset));
            }

            return Ember.RSVP.reject(reason);
          });
        });
      }
    }
  });
});