define('floor/helpers/labeled-url', ['exports', 'floor/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.labeledUrl = labeledUrl;
  function labeledUrl(url) {
    return _environment.default.APP.LABELER_URL + ("/" + url).replace(/^\/\//, '/');
  }

  exports.default = Ember.Helper.helper(labeledUrl);
});