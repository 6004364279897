define('floor/models/price-list-line', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;
  exports.default = Model.extend({
    priceList: belongsTo('priceList'),
    cat: belongsTo('cat'),

    catName: attr('string'),
    catCategory: attr('string'),
    catCode: attr('string'),
    catImageKeys: attr(),

    price: attr('number'),

    priceKey: attr('string'),

    inventoryPrice: attr('number')
  });
});