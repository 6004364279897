define('floor/models/grade-assist', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      belongsTo = _emberData.default.belongsTo,
      attr = _emberData.default.attr;
  exports.default = Model.extend({
    grade: belongsTo('grade'),
    profile: belongsTo('profile', { polymorphic: true }),

    cat: belongsTo('cat'),

    notes: attr('string'),
    reasoning: attr('string'),

    createdAt: attr('date', { readOnly: true })
  });
});