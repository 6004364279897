define('floor/routes/collecting', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model(params) {
      return Ember.RSVP.hash({
        cart: this.store.findRecord('cart', params.id, { include: 'seller,catalog,boxes', reload: true })
      });
    },


    actions: {
      inspectGrade: function inspectGrade(cartLine) {
        this.replaceWith('carts.cart', cartLine.belongsTo('cart').id());
      },
      continueGrading: function continueGrading() {
        this.refresh();
      }
    }
  });
});