define('floor/models/order', ['exports', 'floor/models/importable', 'ember-data'], function (exports, _importable, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo,
      hasMany = _emberData.default.hasMany;
  exports.default = _importable.default.extend({
    pCode: Ember.computed('code', function () {
      return Ember.get(this, 'code') ? 'OR' + Ember.get(this, 'code') : null;
    }),

    status: Ember.computed('closed', 'collected', 'settled', function () {
      if (Ember.get(this, 'settled')) {
        return 'paid';
      } else if (Ember.get(this, 'collected')) {
        return 'collected';
      } else if (Ember.get(this, 'closed')) {
        return 'closed';
      } else {
        return 'open';
      }
    }),

    collector: belongsTo('collector'),

    lines: hasMany('orderLine')
  });
});