define('floor/router', ['exports', 'ember-router-scroll', 'floor/config/environment'], function (exports, _emberRouterScroll, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Router = Ember.Router.extend(_emberRouterScroll.default, {
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });

  Router.map(function () {
    this.route('accept-invitation', { path: '/join/:invitation_token' });
    this.route('forgot-password', { path: '/forgot-password' });
    this.route('reset-password', { path: '/reset-password' });

    this.route('authentication', { path: '/signin' });
    this.route('authenticated', { path: '/' }, function () {
      this.route('settings', { resetNamespace: true }, function () {
        this.route('subscriptions');
      });

      this.route('inventories', { resetNamespace: true, path: '/price-lists' }, function () {
        this.route('inventory', { path: '/:id' });
      });

      this.route('grades', { resetNamespace: true }, function () {
        this.route('resolved');
        this.route('unresolved');

        this.route('grade', { path: '/:id' });
      });

      this.route('grade-resolving', { resetNamespace: true, path: '/grades/:id/resolve' });

      this.route('carts', { resetNamespace: true, path: '/shipments' }, function () {
        this.route('new');

        this.route('unshipped');
        this.route('shipped');
        this.route('accounting');
        this.route('accounted');
        this.route('verified');
        this.route('disbursed');

        this.route('cart', { path: '/:id' });
      });

      this.route('lots', { resetNamespace: true }, function () {
        this.route('new');

        this.route('open');
        this.route('closed');
        this.route('shipped');

        this.route('lot', { path: '/:id' });
      });

      this.route('collecting', { resetNamespace: true, path: '/carts/:id/grading' });
      this.route('collection', { resetNamespace: true, path: '/carts/grades/:id' });
    });

    this.route('not-found', { path: '/*path' });
  });

  exports.default = Router;
});