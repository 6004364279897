define('floor/models/seller', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo,
      hasMany = _emberData.default.hasMany;
  exports.default = Model.extend({
    name: attr('string'),

    locations: hasMany('location'),
    operators: hasMany('operator'),

    phone: attr('string'),

    priceMargin: attr('number', {
      defaultValue: function defaultValue() {
        return 100;
      }
    }),

    collectsGST: attr('boolean'),
    gstNumber: attr('string'),

    cartsCount: attr('number'),
    cartsTotal: attr('number'),

    createdAt: attr('date', { readOnly: true }),

    isDisabled: attr('boolean'),
    archived: attr('boolean'),

    primaryAgent: belongsTo('agent', { inverse: 'primarySellers' }),
    secondaryAgent: belongsTo('agent', { inverse: 'secondarySellers' }),

    primaryAgentComp: attr('number'),
    secondaryAgentComp: attr('number'),

    receivableEmail: attr('string'),

    themePrimaryColor: attr('string'),
    themeSecondaryColor: attr('string'),
    themeTertiaryColor: attr('string'),
    themeAccentColor: attr('string'),

    iconPrimaryColor: attr('string'),

    logoBase: attr('string', { readOnly: true }),
    logoKey: attr('string'),
    logoUrl: attr('string', { readOnly: true }),

    profilesCount: attr('number'),
    profilesMax: attr('number'),

    locationsCount: attr('number'),
    locationsMax: attr('number'),

    expiryExempt: attr('boolean'),

    isBrokering: attr('boolean'),
    everBrokering: attr('boolean'),

    isBrokered: attr('boolean'),
    everBrokered: attr('boolean'),
    brokerName: attr('string'),

    domain: belongsTo('domain'),

    clientsCount: attr('number'),
    collectorsCount: attr('number'),
    consignorsCount: attr('number')
  });
});