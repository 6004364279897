define('floor/adapters/invite', ['exports', 'floor/config/environment', 'ember-data'], function (exports, _environment, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var errorsHashToArray = _emberData.default.errorsHashToArray;
  exports.default = _emberData.default.RESTAdapter.extend({
    host: _environment.default.APP.API_ORIGIN,

    handleResponse: function handleResponse(status, headers, payload) {
      if (this.isInvalid.apply(this, arguments)) {
        payload.errors = errorsHashToArray(payload.errors);
      }

      return this._super.apply(this, arguments);
    }
  });
});