define('floor/routes/authenticated', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    session: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    socketGuru: Ember.inject.service(),

    model: function model() {
      return Ember.get(this, 'currentUser').load();
    },
    afterModel: function afterModel() {
      var sockets = Ember.get(this, 'socketGuru');
      var adapter = Ember.getOwner(this).lookup('adapter:application');
      sockets.setupWithAuthHeaders(adapter.getAuthHeaders());
    },


    actions: {
      logOut: function logOut() {
        Ember.get(this, 'session').invalidate();
        location.href = '/';
      }
    }
  });
});