define('floor/models/agent', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      hasMany = _emberData.default.hasMany;
  exports.default = Model.extend({
    fullName: attr('string'),
    primaryComps: attr({
      defaultValue: function defaultValue() {
        return { 0: "0", 5: "0", 20: "0", 100: "0", 250: "0", 500: "0", 1000: "0" };
      }
    }),
    secondaryComps: attr({
      defaultValue: function defaultValue() {
        return { 0: "0", 5: "0", 20: "0", 100: "0", 250: "0", 500: "0", 1000: "0" };
      }
    }),

    primaryEarnings: attr('string', { readOnly: true }),
    secondaryEarnings: attr('string', { readOnly: true }),

    primarySellers: hasMany('seller', { inverse: 'primaryAgent' }),
    secondarySellers: hasMany('seller', { inverse: 'secondaryAgent' })
  }).reopenClass({
    COMP_RANGE_STARTS: [0, 5, 20, 100, 250, 500, 1000]
  });
});