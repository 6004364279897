define('floor/routes/accountable-grade', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model(params) {
      return this.store.findRecord('collection', params.id, { include: 'cat,cart-line.cart,cart-line.box' });
    }
  });
});