define('floor/routes/grades/resolved', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    infinity: Ember.inject.service(),

    model: function model(params, transition) {
      var sellerId = transition.queryParams.sellerId;

      var include = 'seller,profile,cat,assist.cat,assist.profile';
      var filter = { resolved: true, seller_id: sellerId };

      return Ember.RSVP.hash({
        grades: this.infinity.model('grade', { filter: filter, include: include }),

        sellerId: sellerId
      });
    }
  });
});