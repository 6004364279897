define('floor/controllers/lots/new', ['exports', 'ccon-common/mixins/saving'], function (exports, _saving) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_saving.default, {
    store: Ember.inject.service(),

    actions: {
      saveLot: function saveLot(lot) {
        var _this = this;

        return lot.save().then(function (lot) {
          return _this.replaceRoute('lots.lot', Ember.get(lot, 'id'));
        });
      }
    }
  });
});