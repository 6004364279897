define('floor/helpers/is-sorted-by', ['exports', 'ccon-common/helpers/is-sorted-by'], function (exports, _isSortedBy) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _isSortedBy.default;
    }
  });
  Object.defineProperty(exports, 'isSortedBy', {
    enumerable: true,
    get: function () {
      return _isSortedBy.isSortedBy;
    }
  });
});