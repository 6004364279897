define('floor/components/inventory-peek/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',
    inventory: null
  }).reopenClass({
    positionalParams: ['inventory']
  });
});