define('floor/instance-initializers/segment', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;


  // Taken from ember-test-helpers
  function hasEmberVersion(major, minor) {
    var numbers = Ember.VERSION.split('-')[0].split('.');
    var actualMajor = parseInt(numbers[0], 10);
    var actualMinor = parseInt(numbers[1], 10);
    return actualMajor > major || actualMajor === major && actualMinor >= minor;
  }

  function initialize(appInstance) {
    // Support Ember 1.13+
    var owner = appInstance.lookup ? appInstance : appInstance.container;

    var routerServicePresent = hasEmberVersion(3, 6);

    var router = owner.lookup(routerServicePresent ? 'service:router' : 'router:main');
    var segment = owner.lookup('service:segment');

    // Since Ember v3.6 didTransition is deprecated in favour of routeDidChange
    var eventName = routerServicePresent ? 'routeDidChange' : 'didTransition';

    router.on(eventName, function () {
      var applicationRoute = owner.lookup('route:application');

      if (segment && segment.isPageTrackEnabled()) {
        if (typeof applicationRoute.trackPageView === 'function') {
          applicationRoute.trackPageView();
        } else {
          segment.trackPageView();
        }
      }

      if (segment && segment.isIdentifyUserEnabled()) {
        if (applicationRoute && typeof applicationRoute.identifyUser === 'function') {
          applicationRoute.identifyUser();
        }
      }
    });
  }

  exports.default = {
    name: 'segment',
    initialize: initialize
  };
});