define('floor/initializers/segment', ['exports', 'floor/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize() {
    var application = arguments[1] || arguments[0];

    var _config$segment = _environment.default.segment,
        segment = _config$segment === undefined ? {} : _config$segment;
    var _config$environment = _environment.default.environment,
        environment = _config$environment === undefined ? 'development' : _config$environment;

    var segmentConfig = { segment: segment, environment: environment };

    application.register('config:segment', segmentConfig, { instantiate: false });
    application.inject('service:segment', 'config', 'config:segment');
  }

  exports.default = {
    name: 'segment',
    initialize: initialize
  };
});