define('floor/utils/service-worker-has-update', ['exports', 'ember-service-worker-update-notify/utils/service-worker-has-update'], function (exports, _serviceWorkerHasUpdate) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _serviceWorkerHasUpdate.default;
    }
  });
});