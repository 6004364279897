define('floor/controllers/lots/lot', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    store: Ember.inject.service(),

    actions: {
      saveLot: function saveLot(lot) {
        var _this = this;

        return lot.save().then(function () {
          Ember.get(_this, 'notify').success("Lot saved");
        });
      },
      openLot: function openLot(lot) {
        var _this2 = this;

        Ember.set(lot, 'closed', false);

        return lot.save().then(function () {
          Ember.get(_this2, 'notify').success("Lot re-opened");
        }, function (reason) {
          lot.rollbackAttributes();
          Ember.RSVP.rethrow(reason);
        });
      },
      closeLot: function closeLot(lot) {
        var _this3 = this;

        Ember.set(lot, 'closed', true);

        return lot.save().then(function () {
          Ember.get(_this3, 'notify').success("Lot closed");
        }, function (reason) {
          lot.rollbackAttributes();
          Ember.RSVP.rethrow(reason);
        });
      },
      shipLot: function shipLot(lot) {
        var _this4 = this;

        Ember.set(lot, 'shipped', true);

        return lot.save().then(function () {
          Ember.get(_this4, 'notify').success("Lot shipped");
        }, function (reason) {
          lot.rollbackAttributes();
          Ember.RSVP.rethrow(reason);
        });
      }
    }
  });
});