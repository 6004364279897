define('floor/components/csv-download-button/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'button',

    lotCatsFetcher: Ember.inject.service(),

    lot: null,

    click: function click() {
      Ember.get(this, 'lotCatsFetcher').fetch(Ember.get(this, 'lot'));
    }
  });
});