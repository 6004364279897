define('floor/controllers/grade-resolving', ['exports', 'ccon-common/mixins/saving'], function (exports, _saving) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_saving.default, {
    actions: {
      rejectRequisition: function rejectRequisition(requsition) {
        // set(requsition, 'accepted', false);

        // return requsition.save().then(null, reason => {
        //   requsition.rollbackAttributes();
        //   RSVP.rethrow(reason);
        // });
      },
      saveResolution: function saveResolution(changeset, resolution) {
        return this.validateAndSave(changeset, resolution);
      }
    }
  });
});