define('floor/components/cart-actions/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',

    cart: null,
    privileged: false,

    save: null,
    account: null,
    verify: null,
    disburse: null,

    generateReceipt: null,
    sendReceipt: null
  }).reopenClass({
    positionalParams: ['cart']
  });
});