define('floor/services/errors', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    router: Ember.inject.service(),

    init: function init() {
      var _this = this;

      this._super();

      var oldOnError = Ember.onerror || function () {};

      Ember.onerror = function (err) {
        for (var _len = arguments.length, args = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
          args[_key - 1] = arguments[_key];
        }

        oldOnError.apply.apply(oldOnError, [_this, err].concat(args));

        var controller = Ember.getOwner(_this).lookup('controller:' + Ember.get(_this, 'router.currentRouteName'));

        if (controller) {
          controller.send('error', err);
        }
      };
    }
  });
});