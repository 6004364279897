define('floor/services/lot-cats-fetcher', ['exports', 'floor/services/fetcher', 'floor/config/environment'], function (exports, _fetcher, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _fetcher.default.extend({
    fetch: function fetch(lot) {
      var adapter = Ember.getOwner(this).lookup('adapter:application');
      var auth = adapter.getAuthHeaders()['Authorization'];

      return this.execute(this.getURL(lot), auth);
    },


    getURL: function getURL(lot) {
      var host = _environment.default.APP.API_ORIGIN;
      var namespace = 'data';

      var categoryName = Ember.get(this, 'categoryName');
      var url = [host, namespace, 'lots', Ember.get(lot, 'id'), 'cats'].join('/');

      return url;
    }
  });
});