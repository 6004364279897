define('floor/components/grade-peek/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',

    linkTo: true,
    pointing: true
  }).reopenClass({
    positionalParams: ['grade']
  });
});