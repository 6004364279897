define('floor/helpers/belongs-to-id', ['exports', 'ccon-common/helpers/belongs-to-id'], function (exports, _belongsToId) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _belongsToId.default;
    }
  });
});