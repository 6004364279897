define('floor/components/cart-editor/component', ['exports', 'floor/validations/cart'], function (exports, _cart) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',

    Validations: _cart.default,

    couldVerify: false,
    shouldVerify: false,

    cart: null
  }).reopenClass({
    positionalParams: ['cart']
  });
});