define('floor/models/distribution-line', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      belongsTo = _emberData.default.belongsTo,
      attr = _emberData.default.attr;
  exports.default = Model.extend({
    distribution: belongsTo('distribution'),

    cat: belongsTo('cat'),
    catPrice: attr('number'),

    quantity: attr('number'),
    total: attr('number'),

    box: belongsTo('box')
  });
});