define('floor/services/fetcher', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    requestType: 'GET',

    execute: function execute(url, authorization) {
      var dfr = Ember.RSVP.defer();

      var xhr = new XMLHttpRequest();
      xhr.open(Ember.get(this, 'requestType'), url, true);
      xhr.responseType = 'arraybuffer';

      xhr.onload = function () {
        if (this.status === 200) {
          var filename = '';
          var disposition = xhr.getResponseHeader('Content-Disposition');

          if (disposition && disposition.indexOf('attachment') !== -1) {
            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            var matches = filenameRegex.exec(disposition);

            if (matches != null && matches[1]) {
              filename = matches[1].replace(/['"]/g, '');
            }
          }

          var contentType = xhr.getResponseHeader('Content-Type');
          var blob = new Blob([this.response], { type: contentType });

          if (typeof window.navigator.msSaveBlob !== 'undefined') {
            // IE workaround for "HTML7007: One or more blob URLs were revoked by closing the blob for which they
            // were created. These URLs will no longer resolve as the data backing the URL has been freed."
            window.navigator.msSaveBlob(blob, filename);
          } else {
            var URL = window.URL || window.webkitURL;
            var downloadUrl = URL.createObjectURL(blob);

            if (filename) {
              // use HTML5 a[download] attribute to specify filename
              var a = document.createElement('a');

              if (typeof a.download === 'undefined') {
                window.location = downloadUrl;
              } else {
                a.href = downloadUrl;
                a.download = filename;
                document.body.appendChild(a);
                a.click();
              }
            } else {
              window.location = downloadUrl;
            }

            setTimeout(function () {
              URL.revokeObjectURL(downloadUrl);
            }, 100);
          }

          dfr.resolve();
        } else {
          dfr.reject();
        }
      };

      xhr.setRequestHeader('Authorization', authorization);
      xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');

      xhr.onerror = function () {
        dfr.reject();
      };

      xhr.send();
      return dfr.promise;
    }
  });
});