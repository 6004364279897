define('floor/routes/grade-resolving', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model(params) {
      var _this = this;

      return this.store.findRecord('grade', params.id, { include: 'seller,profile' }).then(function (grade) {
        var seller = Ember.get(grade, 'seller');

        return Ember.get(grade, 'profile').then(function () {
          return Ember.RSVP.hash({
            grade: grade,

            catalog: _this.store.query('catalog', { filter: { seller_id: Ember.get(seller, 'id'), active: true } }).then(function (catalogs) {
              return Ember.get(catalogs, 'firstObject');
            })
          });
        });
      });
    },
    afterModel: function afterModel(hash) {
      if (hash.grade.belongsTo('assist').id()) {
        this.transitionTo('grades.grade', Ember.get(hash.grade, 'id'));
      }
    },


    actions: {
      inspectGrade: function inspectGrade(assist) {
        this.replaceWith('grades.grade', assist.belongsTo('grade').id());
      }
    }
  });
});