define('floor/models/importable', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      belongsTo = _emberData.default.belongsTo,
      attr = _emberData.default.attr;
  exports.default = Model.extend({
    // grade: belongsTo('grade'), // causes some weird double-rendering bugs...

    code: attr('string'),
    determinance: attr('boolean', { allowNull: true }),

    location: belongsTo('location'),
    priceList: belongsTo('priceList'),

    subtotal: attr('number'),
    total: attr('number'),

    markup: attr('number'),
    value: attr('number'),

    isExchanged: attr('boolean', { allowNull: true }),
    exchangeRate: attr('number'),

    exchangedValue: attr('number'),
    exchangedSubtotal: attr('number'),
    exchangedTotal: attr('number'),

    updatedAt: attr('date', { readOnly: true }),
    createdAt: attr('date', { readOnly: true }),

    closed: attr('boolean'),
    settled: attr('boolean'),

    closedAt: attr('date', { readOnly: true }),
    settledAt: attr('date', { readOnly: true })
  });
});