define('floor/models/cat', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr;
  exports.default = Model.extend({
    name: attr('string'),
    category: attr('string'),
    code: attr('string'),

    kind: attr('string'),
    isWeighted: attr('boolean'),

    imageKeys: attr('array', { writeOnly: true }),
    imageUrls: attr('array', { readOnly: true })
  });
});