define('floor/controllers/carts/cart', ['exports', 'ccon-common/mixins/saving'], function (exports, _saving) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_saving.default, {
    store: Ember.inject.service(),

    actions: {
      save: function save(changeset, cart) {
        var _this = this;

        return this.validateAndSave(changeset, cart).then(function () {
          Ember.get(_this, 'notify').success("Shipment saved");
          return cart;
        });
      },
      account: function account(cart) {
        var _this2 = this;

        Ember.set(cart, 'accounted', true);

        return cart.save().then(function () {
          Ember.get(_this2, 'notify').success("Shipment marked as fully graded");
        }, function (reason) {
          cart.rollbackAttributes();
          Ember.RSVP.rethrow(reason);
        });
      },
      verify: function verify(cart) {
        var _this3 = this;

        Ember.set(cart, 'verified', true);

        return cart.save().then(function () {
          Ember.get(_this3, 'notify').success("Shipment verified");
        }, function (reason) {
          cart.rollbackAttributes();
          Ember.RSVP.rethrow(reason);
        });
      },
      generateReceipt: function generateReceipt(cart) {
        var exchanged = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

        Ember.get(this, 'store').createRecord('receipt', { receivable: cart, exchanged: exchanged }).save().then(function (receipt) {
          window.open(Ember.get(receipt, 'url'));
        });
      },
      sendReceipt: function sendReceipt(cart) {
        var exchanged = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

        Ember.get(this, 'store').createRecord('receipt', { receivable: cart, exchanged: exchanged, addressed: true }).save().then(function () {
          window.alert('Receipt sent to ' + Ember.get(cart, 'location.receivableEmail'));
        }, function () {
          window.alert('Could not send receipt');
        });
      }
    }
  });
});