define('floor/components/power-selector/component', ['exports', 'floor/components/power-selector/template', 'ember-concurrency'], function (exports, _template, _emberConcurrency) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,
    store: Ember.inject.service(),

    selected: null,

    onSelect: null,
    onSelectPath: null,

    fetch: null,

    selectedItemTextPath: null,

    init: function init() {
      this._super();

      Ember.get(this, 'fetch').perform();
    },


    actions: {
      onChange: function onChange(selected) {
        Ember.set(this, 'selected', selected);
        this.onSelect(selected ? Ember.get(this, 'onSelectPath') ? Ember.get(selected, Ember.get(this, 'onSelectPath')) : selected : null);
      }
    }
  });
});