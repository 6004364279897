define('floor/models/cart', ['exports', 'floor/models/exportable', 'ember-data'], function (exports, _exportable, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr,
      hasMany = _emberData.default.hasMany;
  exports.default = _exportable.default.extend({
    slug: attr('string', { readOnly: true }),
    key: attr('string', { readOnly: true }),

    lines: hasMany('cartLine'),

    adjustmentIsExchanged: attr('boolean', { allowNull: false }),
    exchangedAdjustmentAmount: attr('number'),

    adjustmentAmount: attr('number'),
    adjustmentDescription: attr('string'),
    adjustmentNotes: attr('string'),

    cost: null,
    value: null,

    primaryComp: attr('number'),
    secondaryComp: attr('number')
  });
});