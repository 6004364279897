define('floor/models/grade', ['exports', 'ember-data', 'ccon-common/utils/specific-polymorphic'], function (exports, _emberData, _specificPolymorphic) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;
  exports.default = Model.extend({
    seller: belongsTo('seller'),
    location: belongsTo('location'),

    profile: belongsTo('profile', { polymorphic: true }),

    accepted: attr('boolean', { allowNull: true }),
    unresolved: Ember.computed('accepted', function () {
      return Ember.get(this, 'accepted') === null;
    }),

    assisted: attr('boolean'),
    assist: belongsTo('gradeAssist'),

    serial: attr(),
    notes: attr(),
    imageKeys: attr('array', { writeOnly: true, defaultValue: function defaultValue() {
        return [];
      }
    }),
    imageUrls: attr('array', { readOnly: true, defaultValue: function defaultValue() {
        return [];
      }
    }),
    quantity: attr('number'),

    createdAt: attr('date', { readOnly: true }),

    box: belongsTo('box'),
    boxLine: belongsTo('boxLine'),

    importable: belongsTo('importable', { polymorphic: true }),
    importableLine: belongsTo('portableLine', { polymorphic: true }),

    order: (0, _specificPolymorphic.default)('importable', 'order'),
    orderLine: (0, _specificPolymorphic.default)('importableLine', 'orderLine'),

    consignment: (0, _specificPolymorphic.default)('importable', 'consignment'),
    consignmentLine: (0, _specificPolymorphic.default)('importableLine', 'consignmentLine'),

    cat: belongsTo('cat')
  });
});