define('floor/routes/settings', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    session: Ember.inject.service(),

    actions: {
      resetPassword: function resetPassword() {
        var email = Ember.get(this, 'currentUser.user.email');

        Ember.get(this, 'session').invalidate();
        this.transitionTo('forgot-password', { queryParams: { email: email } });
      }
    }
  });
});