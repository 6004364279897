define('floor/components/grade-resolution-creator/component', ['exports', 'floor/validations/accepting-grade-resolution', 'floor/validations/rejecting-grade-resolution'], function (exports, _acceptingGradeResolution, _rejectingGradeResolution) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    AcceptingGradeResolutionValidations: _acceptingGradeResolution.default,
    RejectingGradeResolutionValidations: _rejectingGradeResolution.default,

    tagName: '',
    store: Ember.inject.service(),

    grade: null,
    catalog: null,

    assist: null,

    didReceiveAttrs: function didReceiveAttrs() {
      Ember.set(this, 'assist', Ember.get(this, 'store').createRecord('grade-assist', {
        grade: Ember.get(this, 'grade')
      }));
    }
  });
});