define('floor/models/box-line', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;
  exports.default = Model.extend({
    box: belongsTo('box'),
    grade: belongsTo('grade'),
    profile: belongsTo('profile', { polymorphic: true }),

    cat: belongsTo('cat'),
    quantity: attr('number'),

    determinance: attr('boolean', { allowNull: true }),

    latestValuation: belongsTo('boxLineValuation')
  });
});