define('floor/components/sms-subscriber/component', ['exports', 'floor/validations/sms-subscription', 'floor/validations/sms-subscription-confirmation'], function (exports, _smsSubscription, _smsSubscriptionConfirmation) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    Validations: _smsSubscription.default,
    ConfirmationValidations: _smsSubscriptionConfirmation.default
  });
});