define('floor/instance-initializers/notify-of-updates', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize(applicationInstance) {
    // prevent cases where navigator.serviceWorker is null/undefined (in some iOS webviews ?????)
    if (typeof navigator === 'undefined' || 'serviceWorker' in navigator || !navigator.serviceWorker) {
      return;
    }

    var updates = applicationInstance.lookup('service:serviceWorkerUpdateNotify');
    var flash = applicationInstance.lookup('service:flashMessages');

    updates.on('update', function () {
      flash.add({
        priority: 10,

        sticky: true,
        destroyOnClick: false,

        isUpdate: true,
        execute: function execute() {
          window.location.reload();
        }
      });
    });
  }

  exports.default = {
    name: 'notify-of-updates',
    initialize: initialize
  };
});