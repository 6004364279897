define('floor/models/profile', ['exports', 'floor/models/invitable', 'ember-data'], function (exports, _invitable, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo,
      hasMany = _emberData.default.hasMany;
  exports.default = _invitable.default.extend({
    user: belongsTo('user', { inverse: 'profile' }),
    role: attr('string'),

    invitations: hasMany('invitation', { inverse: 'profile' }),
    invitable: attr('boolean')
  });
});