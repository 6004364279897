define('floor/models/location', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo,
      hasMany = _emberData.default.hasMany;
  exports.default = Model.extend({
    code: attr('string'),
    name: attr('string'),

    address: attr('string'),
    receivableEmail: attr('string'),

    seller: belongsTo('seller'),

    priceMarkup: attr('number', { defaultValue: 0 }),
    exchangeRate: attr('number', { defaultValue: 0 }),

    workers: hasMany('worker'),

    orders: hasMany('order'),

    closedImportsCount: attr('number', { readOnly: true }),
    closedImportsTotal: attr('number', { readOnly: true }),
    closedImportsValue: attr('number', { readOnly: true }),

    verifiedExportsCount: attr('number', { readOnly: true }),
    verifiedExportsTotal: attr('number', { readOnly: true }),

    unclosedExportsCount: attr('number', { readOnly: true }), // done
    unclosedBoxesCount: attr('number', { readOnly: true }),
    unsettledOrdersCount: attr('number', { readOnly: true }),

    rejectedGradesCount: attr('number', { readOnly: true }),

    archived: attr('boolean'),
    archivedAt: attr('date', { readOnly: true })
  });
});