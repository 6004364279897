define('floor/routes/settings/subscriptions', ['exports', 'ccon-common/mixins/saving'], function (exports, _saving) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_saving.default, {
    currentUser: Ember.inject.service(),

    model: function model() {
      var _this = this;

      return Ember.get(this, 'currentUser.profile.smsSubscription').then(function (subscription) {
        if (subscription) {
          return subscription;
        } else {
          return _this.store.createRecord('smsSubscription', { employee: Ember.get(_this, 'currentUser.profile') });
        }
      });
    },


    actions: {
      saveSmsSubscription: function saveSmsSubscription(changeset, subscription) {
        var _this2 = this;

        return this.validateAndSave(changeset, subscription).then(function () {
          Ember.get(_this2, 'notify').success("Subscription saved");
        });
      },
      removeSmsSubscription: function removeSmsSubscription(subscription) {
        var _this3 = this;

        return subscription.destroyRecord().then(function () {
          Ember.get(_this3, 'notify').success("Subscription removed");
          return _this3.refresh();
        });
      }
    }
  });
});