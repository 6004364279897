define('floor/components/service-worker-update-notify', ['exports', 'ember-service-worker-update-notify/components/service-worker-update-notify'], function (exports, _serviceWorkerUpdateNotify) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _serviceWorkerUpdateNotify.default;
    }
  });
});