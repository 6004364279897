define('floor/routes/inventories/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    infinity: Ember.inject.service(),

    model: function model() {
      return Ember.RSVP.hash({
        active: this.store.query('inventory', { filter: { active: true } }),

        inactive: this.infinity.model('inventory', Ember.assign({}, { filter: { active: false } }, {
          totalPagesParam: 'meta.page-count',
          countParam: 'meta.record-count'
        }))
      });
    }
  });
});