define('floor/models/lot', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      hasMany = _emberData.default.hasMany;
  exports.default = Model.extend({
    slug: attr('string'),
    warehouse: attr('string'),

    cost: attr('number'),
    foilSubcost: attr('number'),
    nonFoilSubcost: attr('number'),
    otherSubcost: attr('number'),

    closed: attr('boolean'),
    shipped: attr('boolean'),

    carts: hasMany('cart'),
    cartsCount: attr('number', { readOnly: true }),

    createdAt: attr('date', { readOnly: true }),
    closedAt: attr('date', { readOnly: true }),
    shippedAt: attr('date', { readOnly: true }),

    quantities: attr()
  });
});