define('floor/models/collection', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;
  exports.default = Model.extend({
    seller: belongsTo('seller'),

    cart: belongsTo('cart'),
    box: belongsTo('box'),

    cat: belongsTo('cat'),
    quantity: attr('number'),
    notes: attr('string'),

    createdAt: attr('date')
  });
});