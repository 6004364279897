define('floor/routes/accept-invitation', ['exports', 'ember-simple-auth/mixins/unauthenticated-route-mixin'], function (exports, _unauthenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_unauthenticatedRouteMixin.default, {
    model: function model(params) {
      return this.store.findRecord('invite', params['invitation_token']).catch(function () {
        return null;
      });
    }
  });
});