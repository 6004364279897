define('floor/routes/grades/grade', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model(params) {
      return this.store.findRecord('grade', params.id, { include: 'seller,profile,cat,assist.cat,assist.profile' });
    }
  });
});