define('floor/components/each-of', ['exports', 'ember-each-of/components/each-of'], function (exports, _eachOf) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _eachOf.default;
    }
  });
});