define('floor/routes/grades/unresolved', ['exports', 'ember-socket-guru/mixins/socket-event-handler'], function (exports, _socketEventHandler) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_socketEventHandler.default, {
    infinity: Ember.inject.service(),
    socketGuru: Ember.inject.service(),

    model: function model(params, transition) {
      var sellerId = transition.queryParams.sellerId;

      var include = 'seller,profile';
      var filter = { assisted: true, resolved: false, seller_id: sellerId };

      return Ember.RSVP.hash({
        grades: this.infinity.model('grade', { include: include, filter: filter }),

        sellerId: sellerId
      });
    },


    socketActions: {
      recordChanged: function recordChanged(data) {
        var _this = this;

        var type = data.type,
            id = data.id;


        if (type === 'Grade') {
          Ember.run.later(function () {
            _this.store.findRecord(type, id, { include: 'seller,profile,assist.cat', reload: true }).then(function (req) {
              var model = Ember.get(_this.controllerFor(_this.routeName), 'model');

              if (model) {
                if (Ember.get(req, 'accepted') !== null) {
                  model.grades.removeObject(req);
                } else {
                  model.grades.addObject(req);
                }
              }
            });
          }, 3000);
        }
      }
    }
  });
});