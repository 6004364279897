define('floor/models/catalog', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr;
  exports.default = Model.extend({
    slug: attr('string'),
    code: attr('string'),

    priceMargin: attr('number'),

    createdAt: attr('date')
  });
});